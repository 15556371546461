import { Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getTeamRent } from "../redux/actions/rentAction";
import { rentIncompleteSvg, rentCompleteSvg, nonRentedSvg, nonRentedCompleteSvg } from "../svgs/svgs";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { setRentYear } from "../redux/reducers/rentyearReducer";

const RentPage = ({ setisLoading }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.userReducer.user);
    const rent = useSelector((state) => state.rentReducer.rent);
    const team = useSelector((state) => state.userReducer.teamUser);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const { id } = useParams();

    // Add selected year state
    const currentYear = new Date().getFullYear();
    const selectedYear = useSelector((state) => state.rentYear.selectedYear);

    // Generate year options from 2024 to current year
    const yearOptions = [];
    for (let year = 2024; year <= currentYear; year++) {
        yearOptions.push(year);
    }

    useEffect(() => {
        if (team) {
            const filtered = team.filter(user => {
                if (user.booking === "all") {
                    return true;
                } else {
                    const ids = user.booking.split(',').map(item => item.trim());
                    return ids.includes(id);
                }
            });
            setFilteredUsers(filtered);
        }
    }, [id, team]);

    useEffect(() => {
        dispatch(getTeamRent(user?.teamId, setisLoading));
    }, [user?.teamId, dispatch]);

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    return (
        <div className="h-[100vh] overflow-hidden">
            <div className="h-[8%] flex justify-between items-center ps-1 pe-3 border-b border-[#e7eoec]">
                <div className="flex justify-start items-center gap-x-1">
                    <div onClick={() => navigate("/booking")} className="flex p-2 rounded-full justify-center items-center">
                        <BiArrowBack size={24} />
                    </div>
                    <div className="text-xl font-medium cursor-pointer">Rent</div>
                </div>
                <div className="flex gap-x-3 items-center">
                    <div>
                        <Select
                            value={selectedYear.toString()}
                            onValueChange={(value) => {
                                dispatch(setRentYear(parseInt(value)))
                            }}
                        >
                            <SelectTrigger className="w-full rounded-[8px] h-[35px] bg-transparent border border-black">
                                <SelectValue placeholder="Select year" />
                            </SelectTrigger>
                            <SelectContent>
                                {yearOptions.map((year) => (
                                    <SelectItem
                                        key={year}
                                        value={year.toString()}
                                        className="cursor-pointer rounded-[8px]"
                                    >
                                        {year}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    {user?.role === "admin" && (
                        <div onClick={() => navigate(`/manageaccess/${id}`)} className='flex'>
                            <img src={user?.imageUrl} className='h-[28px] z-30 w-[28px] rounded-full' alt="" />
                            <img src={user?.imageUrl !== filteredUsers[0]?.imageUrl ? filteredUsers[0]?.imageUrl : filteredUsers[1]?.imageUrl} className='h-[28px] z-20 ml-[-9px] w-[28px] rounded-full' alt="" />
                            {filteredUsers.length > 2 && (
                                <div className='h-[28px] text-white z-10 flex justify-center items-center ml-[-9px] w-[28px] rounded-full bg-[#003C9A] text-[14px]'>+{filteredUsers.length - 2}</div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div className="h-[92%] overflow-auto px-3 py-3">
                {rent.map((item, index) => (
                    <div key={index} className={`${item?.rentStatus ? "bg-[#ECEDF9]" : "bg-[#F33] text-white"} p-2 mb-3 rounded-[8px]`}>
                        <div onClick={() => navigate(`/rent-details/${item._id}/${index + 1}/${id}`)} className="flex justify-between font-medium text-lg px-1 py-2">
                            <div>{index + 1}.</div>
                            <div className="capitalize">{item.projectName}</div>
                            <div>{item.propertyNumber}</div>
                            <div>{item.rentAmount}/m</div>
                        </div>
                        <div className="border-b my-1 border-[#E1E2EE]" />
                        <div className="px-1 py-2">
                            <div className="flex justify-between items-center">
                                {months.map((month, index) => (
                                    <div key={index} className="w-full text-center">{index + 1}</div>
                                ))}
                            </div>
                            <div className="flex justify-between items-center">
                                {months.map((month, index) => {
                                    // Use selectedYear instead of currentYear
                                    const rentCompleteItem = item?.rentComplete?.find(
                                        rentItem => rentItem.month === month && rentItem.year === selectedYear
                                    );
                                    const isRentComplete = rentCompleteItem && rentCompleteItem.amount !== 0;

                                    let SvgComponent;
                                    if (item?.rentStatus) {
                                        SvgComponent = isRentComplete ? rentCompleteSvg : rentIncompleteSvg;
                                    } else {
                                        SvgComponent = isRentComplete ? nonRentedCompleteSvg : nonRentedSvg;
                                    }

                                    return (
                                        <div key={index} className="w-full mt-2 flex justify-center items-center">
                                            {SvgComponent}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="fixed bottom-32 right-20">
                <button
                    className="h-[62px] w-[62px] flex justify-center items-center plus absolute active:scale-[0.9] transition-all rounded-[16px]"
                    onClick={() => navigate(`/addrent/${id}`)}
                >
                    <Plus size={34} color="#fff" />
                </button>
            </div>
        </div>
    );
}

export default RentPage;