import axios from "axios";
import { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { baseUrl } from "../lib/base-url";
import { SendHorizontal } from "lucide-react";
import { contactSvg, deleteSvg, paymentSvg, pencilSvg, smallDeleteSvg, whatsappSvg } from "../svgs/svgs";
import { Dialog, DialogClose, DialogContent, DialogTrigger } from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import { useDispatch, useSelector } from "react-redux";
import { deleteFlat, updateFlatById } from "../redux/actions/flatAction";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
} from "../components/ui/select";

const FlatDetails = ({ setisLoading }) => {
    const navigate = useNavigate();
    const { id, projectId } = useParams();
    const [flat, setFlat] = useState(null);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.userReducer.user);
    const [note, setNote] = useState("");
    const [role, setRole] = useState(false);
    const [projectName, setProjectName] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirect = queryParams.get('redirect');

    useEffect(() => {
        axios.get(`${baseUrl}/api/project/${projectId}`)
            .then((res) => setProjectName(res.data.projectName))
            .catch((err) => { })
    }, [projectId]);

    useEffect(() => {
        if (user?.role === "sitemanager") {
            setRole(false);
        } else {
            setRole(true);
        }
    }, [user]);

    useEffect(() => {
        getFunction(id);
    }, [id]);

    const getFunction = (id) => {
        axios.get(`${baseUrl}/api/flat/${id}`)
            .then((res) => setFlat(res.data))
            .catch((err) => { })
    }

    const handlePhoneIconClick = (number) => {
        if (number) {
            window.location.href = `tel:${number}`;
        }
    };

    const handleWhatsappIconClick = (number) => {
        if (number) {
            // Assuming contact is a valid WhatsApp number
            const whatsappLink = `https://wa.me/${number.replace(/\s/g, "")}`;
            window.location.href = whatsappLink;
        }
    };

    const handleDelete = () => {
        dispatch(deleteFlat(id, projectId));
        navigate(`/project/${projectId}`);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.get(`${baseUrl}/api/flat/${id}`)
            .then((res) => {
                const existingArr = res.data.notes || []; // Retrieve existing notes array or initialize as empty array if not present

                const noteObj = {
                    note: note,
                    createdBy: user?.userName,
                    imageUrl: user?.imageUrl,
                    createdAt: Date.now(),
                };

                // Create a new array by appending the new noteObj to the existing notes array
                const updatedNotes = [...existingArr, noteObj];

                // Dispatch action to update flat by replacing notes array with the updated one
                dispatch(updateFlatById(id, { notes: updatedNotes }, projectId, setisLoading, getFunction, user?.userName, user?.imageUrl, `${note}`, `commented ${flat?.series} ${projectName}`, `/flat-details/${id}/${projectId}`));

                // Clear the note input field
                setNote("");
            })
            .catch((err) => {
                console.error("Error fetching flat details:", err);
                setNote("");
            });
    };

    const handleDeleteMessage = (timestamp) => {
        // Filter out the message with the given timestamp
        const updatedNotes = flat.notes.filter(note => note.createdAt !== timestamp);
        // Update the notes array
        dispatch(updateFlatById(id, { notes: updatedNotes }, projectId, setisLoading, getFunction, user?.userName, user?.imageUrl, `Deleted a message from ${flat?.series} ${projectName}`, `deleted a message from ${flat?.series} ${projectName}`, `/flat-details/${id}/${projectId}`));
    };

    return (
        <div className="h-[100vh]">
            <div className=" h-[8vh] flex justify-between items-center ps-1 pe-3">
                <div className=" flex justify-start items-center gap-x-1">
                    <div>
                        <div className="flex p-2 rounded-full justify-center items-center">
                            <BiArrowBack onClick={() => {
                                navigate(redirect)
                            }} size={24} />
                        </div>
                    </div>
                    <div className=" text-xl font-semibold cursor-pointer">{flat?.series}</div>
                </div>
                <div className="flex justify-center gap-x-8">
                    <div className={`h-[20px] mt-[3px] rounded-[5px] ${flat?.status ? flat?.status === "red" ? "bg-[#f33]" : "bg-yellow-300" : "sc"} w-[20px]`} />
                    {user?.role === "admin" && (
                        <>
                            {flat?.customerName !== "" && (
                                <div onClick={() => navigate(`/payment-detail/${id}/${projectId}?redirect=${redirect}`)} className="p-[2px]">{paymentSvg}</div>
                            )}
                        </>
                    )}
                    <div onClick={() => navigate(`/flat-edit/${id}/${projectId}/?redirect=${redirect}`)}>{pencilSvg}</div>
                    {(user?.teamId === "2acd2ec8-5532-4710-bcc0-5c467b96b44a" ? user?.email === "maheshsangani49@gmail.com" : user?.role === "admin") && (
                        <div>
                            <Dialog>
                                <DialogTrigger>
                                    {deleteSvg}
                                </DialogTrigger>
                                <DialogContent
                                    className=" text-center w-[95%] rounded-[5px]"
                                >
                                    <div className=" text-xl font-semibold">Are you sure</div>
                                    <div>You want to delete this</div>
                                    <div className=" flex justify-between items-center">
                                        <DialogClose>
                                            <Button
                                                variant="primary"
                                                className=" bg-indigo-500 text-white font-semibold"
                                                style={{ borderRadius: "5px" }}
                                            >
                                                Cancel
                                            </Button>
                                        </DialogClose>
                                        <DialogClose>
                                            <Button
                                                onClick={() => handleDelete(id)}
                                                variant="primary"
                                                className=" bg-red-500 text-white font-semibold"
                                            >
                                                Delete
                                            </Button>
                                        </DialogClose>
                                    </div>
                                </DialogContent>
                            </Dialog>
                        </div>
                    )}
                </div>
            </div>
            <div className="h-[84vh] overflow-y-auto">
                <div className="px-3 border-b border-b-[#DEE2F4]">
                    {flat?.customerName !== "" && (
                        <div className="flex flex-col gap-y-2 border-b border-[#DEE2F4] pb-3">
                            <div className="text-lg">Name</div>
                            <div className="text-xl font-semibold">{flat?.customerName}</div>
                        </div>
                    )}
                    {(flat?.mobileNumber !== undefined && flat?.mobileNumber !== "") && (
                        <div className="flex justify-between gap-y-2 border-b border-[#DEE2F4] py-3">
                            <div className="flex flex-col gap-y-2">
                                <div className="text-lg">Mobile No.</div>
                                <div className="text-xl font-semibold">+91 {flat?.mobileNumber?.substring(0, 5) + ' ' + flat?.mobileNumber?.substring(5)}</div>
                            </div>
                            <div className="flex gap-x-2 items-center">
                                <div onClick={() => handleWhatsappIconClick(flat?.mobileNumber)}>{whatsappSvg}</div>
                                <div onClick={() => handlePhoneIconClick(flat?.mobileNumber)} className="p-[8px] bg-black rounded-full">{contactSvg}</div>
                            </div>
                        </div>
                    )}
                    {(flat?.alternateMobileNumber !== undefined && flat?.alternateMobileNumber !== "") && (
                        <div className="flex justify-between gap-y-2 border-b border-[#DEE2F4] py-3">
                            <div className="flex flex-col gap-y-2">
                                <div className="text-lg">Alternate Mobile No.</div>
                                <div className="text-xl font-semibold">+91 {flat?.alternateMobileNumber?.substring(0, 5) + ' ' + flat?.alternateMobileNumber?.substring(5)}</div>
                            </div>
                            <div className="flex gap-x-2 items-center">
                                <div onClick={() => handleWhatsappIconClick(flat?.alternateMobileNumber)}>{whatsappSvg}</div>
                                <div onClick={() => handlePhoneIconClick(flat?.alternateMobileNumber)} className="p-[8px] bg-black rounded-full">{contactSvg}</div>
                            </div>
                        </div>
                    )}
                    {(flat?.brokerName !== undefined && flat?.brokerName !== "") && (
                        <div className="flex flex-col gap-y-2 border-b border-[#DEE2F4] py-3">
                            <div className="text-lg">Broker/Dalal Name</div>
                            <div className="text-xl font-semibold">{flat?.brokerName}</div>
                        </div>
                    )}
                    {(flat?.brokerNumber !== undefined && flat?.brokerNumber !== "") && (
                        <div className="flex justify-between gap-y-2 border-b border-[#DEE2F4] py-3">
                            <div className="flex flex-col gap-y-2">
                                <div className="text-lg">Broker/Dalal Mobile No.</div>
                                <div className="text-xl font-semibold">+91 {flat?.brokerNumber?.substring(0, 5) + ' ' + flat?.brokerNumber?.substring(5)}</div>
                            </div>
                            <div className="flex gap-x-2 items-center">
                                <div onClick={() => handleWhatsappIconClick(flat?.brokerNumber)}>{whatsappSvg}</div>
                                <div onClick={() => handlePhoneIconClick(flat?.brokerNumber)} className="p-[8px] bg-black rounded-full">{contactSvg}</div>
                            </div>
                        </div>
                    )}
                    {flat?.customerName !== "" && (
                        <div className="flex justify-between border-b border-[#DEE2F4] py-3">
                            <div>Deed status</div>
                            {
                                role ? (
                                    <Select
                                        value={flat?.isDeed === true ? "complete" : "incomplete"}
                                        onValueChange={(value) => {
                                            dispatch(updateFlatById(id, { isDeed: value === "complete" ? true : false }, projectId, setisLoading, getFunction, user?.userName, user?.imageUrl, `Changed ${flat?.series} ${projectName} Deed to ${value === "complete" ? "completed" : "incomplete"}`, `updated ${flat?.series} ${projectName}`, `/flat-details/${id}/${projectId}`))
                                        }}
                                    >
                                        <SelectTrigger
                                            className={`${flat?.isDeed ? " bg-[#28A745]" : "bg-[#6C757D]"
                                                } w-[80px] font-[400] h-[30px] shadow-none border-0 text-white`}
                                        >
                                            Deed
                                        </SelectTrigger>
                                        <SelectContent className="rounded-[5px]">
                                            <SelectItem className="select" value={"complete"}>
                                                complete
                                            </SelectItem>
                                            <SelectItem className="select" value={"incomplete"}>
                                                incomplete
                                            </SelectItem>
                                        </SelectContent>
                                    </Select>
                                ) : (
                                    <div>
                                        <div className={`${flat?.isDeed ? " bg-[#28A745]" : "bg-[#6C757D]"
                                            } flex items-center justify-center rounded-[5px] w-[70px] font-[400] h-[30px] shadow-none border-0 text-white`}
                                        >
                                            Deed
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )}
                    {flat?.squareFeet !== null && (
                        <div className="flex justify-between gap-y-2 py-3">
                            <div className="text-xl font-semibold">{flat?.squareFeet} Sqft</div>
                            {flat?.bhkSize !== "none" && <div className="text-xl font-semibold">{flat?.bhkSize} BHK</div>}
                        </div>
                    )}
                </div>
                <div className="px-3">
                    {flat?.notes?.map((note, index) => (
                        <MessageComp
                            key={index}
                            name={note?.createdBy}
                            imageUrl={note?.imageUrl}
                            message={note?.note}
                            time={note?.createdAt}
                            onDelete={handleDeleteMessage}
                        />
                    ))}
                </div>
            </div>
            <div className="h-[8vh]">
                <div className={`w-full mb-3 px-3 bg-[#FAF8FF]`}>
                    <form onSubmit={handleSubmit} className='flex items-center gap-x-2'>
                        <input
                            type="text"
                            className='h-[50px] focus:outline-none px-3 rounded-full w-full'
                            placeholder='Send Message'
                            name='note'
                            onChange={(e) => {
                                setNote(e.target.value);
                            }}
                            value={note}
                        />
                        <button disabled={!note.trim()} className='bg-[#0066FA] p-2 rounded-full' type='submit'><SendHorizontal color="white" /></button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default FlatDetails;

export const MessageComp = ({ name, imageUrl, message, time, onDelete }) => {
    const [showDialog, setShowDialog] = useState(false);
    const user = useSelector((state) => state.userReducer.user);
    const ids = process.env.REACT_APP_DELETE_IDS.split(",");

    useEffect(() => {
        const checkConditions = () => {
            const currentTime = new Date();
            const messageTime = new Date(time);
            const timeDifference = (currentTime - messageTime) / 1000; // difference in seconds

            if (ids.includes(user?.userId)) {
                setShowDialog(true);
            } else if (name === user?.userName && timeDifference < 60) {
                setShowDialog(true);
            } else {
                setShowDialog(false);
            }
        };

        checkConditions();
    }, [ids, user, name, time]);

    function formatDate(timestamp) {
        const date = new Date(timestamp);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const period = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        hours = hours % 12 || 12;

        // Add leading zeros if necessary
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;
        const formattedHours = hours < 10 ? '0' + hours : hours;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

        return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes} ${period}`;
    }

    return (
        <div className='mt-3'>
            <div className='flex gap-x-3'>
                <img src={imageUrl} className='h-8 w-8 rounded-full' alt="user" />
                <div className="w-full">
                    <div className="flex justify-between">
                        <div className='flex justify-start flex-wrap items-center gap-x-2'>
                            <div className='font-medium text-md'>{name}</div>
                            <div className='text-[10px] text-[#424655]'>{formatDate(time)}</div>
                        </div>
                        {showDialog && (
                            <div>
                                <Dialog>
                                    <DialogTrigger>
                                        {smallDeleteSvg}
                                    </DialogTrigger>
                                    <DialogContent
                                        className=" text-center w-[95%] rounded-[5px]"
                                    >
                                        <div className=" text-xl font-semibold">Delete this chat</div>
                                        <div className=" flex justify-between items-center">
                                            <DialogClose>
                                                <Button
                                                    variant="primary"
                                                    className=" bg-indigo-500 text-white font-semibold rounded-[5px]"
                                                >
                                                    Cancel
                                                </Button>
                                            </DialogClose>
                                            <DialogClose>
                                                <Button
                                                    variant="primary"
                                                    className=" bg-red-500 text-white font-semibold rounded-[5px]"
                                                    onClick={() => onDelete(time)}
                                                >
                                                    Delete
                                                </Button>
                                            </DialogClose>
                                        </div>
                                    </DialogContent>
                                </Dialog>
                            </div>
                        )}
                    </div>
                    <div className='text-md w-full'>
                        {message}
                    </div>
                </div>
            </div>
        </div>
    );
};