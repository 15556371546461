import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../lib/base-url";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogClose, DialogContent, DialogTitle, DialogTrigger } from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import { addPayment, editPayment, getPayment } from "../redux/actions/paymentAction";
import { updateFlatById } from "../redux/actions/flatAction";

const PaymentDetails = ({ setisLoading }) => {
    const { id, projectId } = useParams();
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const [flat, setFlat] = useState();
    const dispatch = useDispatch();
    const paymentArr = useSelector((state) => state.paymentReducer.payment);
    const user = useSelector((state) => state.userReducer.user);
    const [amountType, setAmountType] = useState("cash");
    let [payment, setPayment] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirect = queryParams.get('redirect');

    useEffect(() => {
        dispatch(getPayment(id));
        !user?.superAdmin && setAmountType('cheque')
    }, [id, dispatch, user]);

    useEffect(() => {
        if (id) {
            getFunction(id);
        }
    }, [id]);

    useEffect(() => {
        flat?.loanStatus === undefined && setProgress(0);
        flat?.loanStatus === "Application" && setProgress(1);
        flat?.loanStatus === "Process" && setProgress(2);
        flat?.loanStatus === "Sanctioned" && setProgress(3);
    }, [flat]);

    const getFunction = (id) => {
        axios.get(`${baseUrl}/api/flat/${id}`)
            .then((res) => {
                setFlat(res.data)
            })
            .catch((err) => console.log(err));
    };

    function convertPriceToIndianNumberingSystem(price) {
        const digits = price.toString().split("");

        digits.reverse();

        for (let i = 3; i < digits.length; i += 3) {
            digits.splice(i, 0, ",");
        }

        const indianPrice = digits.join("");
        const payment = indianPrice.split('').reverse().join('');

        return payment;
    }

    function formatDateTime(dateString) {
        const date = new Date(dateString);
    
        // Format the date part
        const day = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'short' }); // Get short month name
        const year = date.getFullYear();
    
        // Format the time part
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const period = hours >= 12 ? 'PM' : 'AM';
    
        // Convert to 12-hour format
        hours = hours % 12 || 12;
    
        return `${day} ${month} ${year} | ${hours}:${minutes} ${period}`;
    }

    const renderOutstandingAmount = () => {
        if (amountType === "cash") {
            return flat?.outstandingAmountCash ? (
                <div className="h-full relative">
                    <div className="px-3 py-2 h-[calc(100%-70px)] overflow-y-auto">
                        <div className="border py-[0.75rem] px-[1rem] bg-[#F7F2FA] rounded-[0.375rem]">
                            <div className="flex justify-between text-[1.25rem]" style={{ lineHeight: '150%' }}>
                                <div>
                                    <div>Outstanding:</div>
                                    <div>₹{convertPriceToIndianNumberingSystem(flat?.outstandingAmountCash)}</div>
                                </div>
                                <div>
                                    <div>Receivable:</div>
                                    <div className="text-[#299F2D]">
                                        ₹{convertPriceToIndianNumberingSystem(
                                            (flat?.outstandingAmountCash || 0) -
                                            paymentArr
                                                .filter(item => item.amountType === 'cash')
                                                .reduce((sum, item) => sum + (item?.amount || 0), 0)
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {paymentArr.filter(item => item.amountType === 'cash').length > 0 && (
                                <div className="text-[1rem] mt-[1rem] font-[500]">
                                    {paymentArr.filter(item => item.amountType === 'cash').length} Payments
                                </div>
                            )}
                            {paymentArr.filter(item => item.amountType === 'cash').map((item, index) => (
                                <div key={index} className="mt-[1rem]">
                                    <div className="py-[0.75rem] px-[1rem] bg-[#ECEDF9] rounded-[0.375rem]">
                                        <div className="flex flex-wrap justify-between items-center">
                                            <div className="flex items-center gap-x-3">
                                                <img
                                                    src={item?.imageUrl}
                                                    alt={index}
                                                    className="h-[2.5rem] w-[2.5rem] rounded-full shrink-0"
                                                />
                                                <div>
                                                    <div
                                                        className="text-[1.125rem] font-[500]"
                                                    >{item?.userName}</div>
                                                    <div
                                                        className="text-sm break-words"
                                                    >{formatDateTime(item?.createdAt)}</div>
                                                </div>
                                            </div>
                                            <div className="text-[#299F2D] text-[1.25rem] font-[500]">
                                                +₹{convertPriceToIndianNumberingSystem(item?.amount)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="absolute bottom-0 left-0 w-full">
                        <AddEntry
                            flat={flat}
                            user={user}
                            amountType={amountType}
                        />
                    </div>
                </div>
            ) : (
                <AddOutstandingAmount
                    type={amountType}
                    flat={flat}
                    setisLoading={setisLoading}
                    getFunction={getFunction}
                />
            );
        }
        if (amountType === "cheque") {
            return flat?.outstandingAmountCheque ? (
                <div className="h-full relative">
                    <div className="px-3 py-2 h-[calc(100%-70px)] overflow-y-auto">
                        <div className="border py-[0.75rem] px-[1rem] bg-[#F7F2FA] rounded-[0.375rem]">
                            <div className="flex justify-between text-[1.25rem]" style={{ lineHeight: '150%' }}>
                                <div>
                                    <div>Outstanding:</div>
                                    <div>₹{convertPriceToIndianNumberingSystem(flat?.outstandingAmountCheque)}</div>
                                </div>
                                <div>
                                    <div>Receivable:</div>
                                    <div className="text-[#299F2D]">
                                        ₹{convertPriceToIndianNumberingSystem(
                                            (flat?.outstandingAmountCheque || 0) -
                                            paymentArr
                                                .filter(item => item.amountType === 'cheque')
                                                .reduce((sum, item) => sum + (item?.amount || 0), 0)
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {paymentArr.filter(item => item.amountType === 'cheque').length > 0 && (
                                <div className="text-[1rem] mt-[1rem] font-[500]">
                                    {paymentArr.filter(item => item.amountType === 'cheque').length} Payments
                                </div>
                            )}
                            {paymentArr.filter(item => item.amountType === 'cheque').map((item, index) => (
                                <div key={index} className="mt-[1rem]">
                                    <div className="py-[0.75rem] px-[1rem] bg-[#ECEDF9] rounded-[0.375rem]">
                                        <div className="flex flex-wrap justify-between items-center">
                                            <div className="flex items-center gap-x-3">
                                                <img
                                                    src={item?.imageUrl}
                                                    alt={index}
                                                    className="h-[2.5rem] w-[2.5rem] rounded-full shrink-0"
                                                />
                                                <div>
                                                    <div
                                                        className="text-[1.125rem] font-[500]"
                                                    >{item?.userName}</div>
                                                    <div
                                                        className="text-sm"
                                                    >{formatDateTime(item?.createdAt)}</div>
                                                </div>
                                            </div>
                                            <div className="text-[#299F2D] text-[1.25rem] font-[500]">
                                                +₹{convertPriceToIndianNumberingSystem(item?.amount)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="absolute bottom-0 left-0 w-full">
                        <AddEntry
                            flat={flat}
                            user={user}
                            amountType={amountType}
                        />
                    </div>
                </div>
            ) : (
                <AddOutstandingAmount
                    type={amountType}
                    flat={flat}
                    setisLoading={setisLoading}
                    getFunction={getFunction}
                />
            );
        }
        if (amountType === "other") {
            return flat?.outstandingAmountOther ? (
                <div className="h-full relative">
                    <div className="px-3 py-2 h-[calc(100%-70px)] overflow-y-auto">
                        <div className="border py-[0.75rem] px-[1rem] bg-[#F7F2FA] rounded-[0.375rem]">
                            <div className="flex justify-between text-[1.25rem]" style={{ lineHeight: '150%' }}>
                                <div>
                                    <div>Outstanding:</div>
                                    <div>₹{convertPriceToIndianNumberingSystem(flat?.outstandingAmountOther)}</div>
                                </div>
                                <div>
                                    <div>Receivable:</div>
                                    <div className="text-[#299F2D]">
                                        ₹{convertPriceToIndianNumberingSystem(
                                            (flat?.outstandingAmountOther || 0) -
                                            paymentArr
                                                .filter(item => item.amountType === 'other')
                                                .reduce((sum, item) => sum + (item?.amount || 0), 0)
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {paymentArr.filter(item => item.amountType === 'other').length > 0 && (
                                <div className="text-[1rem] mt-[1rem] font-[500]">
                                    {paymentArr.filter(item => item.amountType === 'other').length} Payments
                                </div>
                            )}
                            {paymentArr.filter(item => item.amountType === 'other').map((item, index) => (
                                <div key={index} className="mt-[1rem]">
                                    <div className="py-[0.75rem] px-[1rem] bg-[rgb(236,237,249)] rounded-[0.375rem]">
                                        <div className="flex flex-wrap justify-between items-center">
                                            <div className="flex items-center gap-x-3">
                                                <img
                                                    src={item?.imageUrl}
                                                    alt={index}
                                                    className="h-[2.5rem] w-[2.5rem] rounded-full shrink-0"
                                                />
                                                <div>
                                                    <div
                                                        className="text-[1.125rem] font-[500]"
                                                    >{item?.userName}</div>
                                                    <div
                                                        className="text-sm"
                                                    >{formatDateTime(item?.createdAt)}</div>
                                                </div>
                                            </div>
                                            <div className="text-[#299F2D] text-[1.25rem] font-[500]">
                                                +₹{convertPriceToIndianNumberingSystem(item?.amount)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="absolute bottom-0 left-0 w-full">
                        <AddEntry
                            flat={flat}
                            user={user}
                            amountType={amountType}
                        />
                    </div>
                </div>
            ) : (
                <AddOutstandingAmount
                    type={amountType}
                    flat={flat}
                    setisLoading={setisLoading}
                    getFunction={getFunction}
                />
            );
        }
    };

    return (
        <div className="h-[100vh] overflow-auto">
            <div className="h-[8vh] flex justify-between items-center ps-1 pe-3">
                <div className="flex justify-start items-center gap-x-1">
                    <div>
                        <div className="flex p-2 rounded-full justify-center items-center">
                            <BiArrowBack
                                onClick={() => {
                                    navigate(`/flat-details/${id}/${projectId}?redirect=${redirect}`);
                                }}
                                size={24}
                            />
                        </div>
                    </div>
                    <div className="text-xl font-semibold cursor-pointer">{flat?.series}</div>
                </div>
            </div>
            <div className="h-[92vh]">
                <div className="border-b h-[5vh]">
                    <div className="flex justify-between items-center h-full">
                        {user?.superAdmin === true && (
                            <div
                                onClick={() => setAmountType("cash")}
                                className={`flex justify-center items-center w-full h-full pb-3 ${amountType === "cash" ? "border-b-[2px] border-[#0066FA]" : "hover:border-b-[2px] border-transparent"}`}
                            >
                                Cash
                            </div>
                        )}

                        <div
                            onClick={() => setAmountType("cheque")}
                            className={`flex justify-center items-center w-full h-full pb-3 ${amountType === "cheque" ? "border-b-[2px] border-[#0066FA]" : "hover:border-b-[2px] border-transparent"}`}
                        >
                            Cheque
                        </div>

                        <div
                            onClick={() => setAmountType("other")}
                            className={`flex justify-center items-center w-full h-full pb-3 ${amountType === "other" ? "border-b-[2px] border-[#0066FA]" : "hover:border-b-[2px] border-transparent"}`}
                        >
                            Other
                        </div>
                    </div>
                </div>
                <div className="h-[87vh]">
                    {renderOutstandingAmount()}
                </div>
            </div>
        </div>
    );
};

export default PaymentDetails;

const AddOutstandingAmount = ({ type, flat, setisLoading, getFunction }) => {
    const [value, setValue] = useState("");
    const dispatch = useDispatch();

    const formatNumber = (val) => {
        return val
            .replace(/\D/g, "")
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    };

    const handleChange = (e) => {
        const formattedValue = formatNumber(e.target.value);
        setValue(formattedValue);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const numericValue = value.replace(/,/g, "");

        let updateData;
        if (type === "cash") {
            updateData = { outstandingAmountCash: numericValue };
        } else if (type === "cheque") {
            updateData = { outstandingAmountCheque: numericValue };
        } else if (type === "other") {
            updateData = { outstandingAmountOther: numericValue };
        }

        dispatch(updateFlatById(
            flat?._id,
            updateData,
            flat?.projectId,
            setisLoading,
            getFunction
        ));
    };

    return (
        <form className="h-full overflow-hidden" onSubmit={handleSubmit}>
            <div className="py-[1.5rem] px-[1.47rem] border border-[#727687] rounded-[0.5rem] mt-[8rem]">
                <div className="text-[1.25rem] font-[500]">Add initial Outstanding Amount</div>
                <div className="mt-[1.5rem] mb-[0.5rem] text-[1.125rem]">Outstanding Amount</div>
                <input
                    type="text"
                    className="w-full sc px-3 outline-none h-[50px]"
                    placeholder="1,00,000"
                    style={{ borderRadius: "5px" }}
                    name="customerName"
                    value={value}
                    onChange={handleChange}
                />
                <Button
                    type="submit"
                    variant={"primary"}
                    className="flex active:bg-opacity-70 gap-x-2 mt-3 text-[18px] h-[50px] py-[24px] text-white justify-center items-center plus w-full font-[500] rounded-[8px]"
                >
                    Add
                </Button>
            </div>
        </form>
    );
};


const AddEntry = ({ flat, user, amountType }) => {
    const [value, setValue] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const dispatch = useDispatch();

    const formatNumber = (val) => {
        return val
            .replace(/\D/g, "")
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    };

    const handleChange = (e) => {
        const formattedValue = formatNumber(e.target.value);
        setValue(formattedValue);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const numericValue = value.replace(/,/g, "");

        const payment = {
            amount: numericValue,
            flatId: flat?._id,
            teamId: flat?.teamId,
            amountType: amountType,
            userName: user?.userName,
            imageUrl: user?.imageUrl,
        };

        dispatch(addPayment(payment, flat?._id));
        setIsDialogOpen(false);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setValue("");
    };

    return (
        <div className="bg-[#F3EDF7] py-[0.75rem] flex justify-center lg:max-w-[500px] h-[70px] pb-[1vh]">
            <Dialog
                open={isDialogOpen}
                onOpenChange={(open) => {
                    if (!open) handleDialogClose();
                }}
            >
                <DialogTrigger>
                    <button
                        className="bg-[#299F2D] py-[0.75rem] px-[2.25rem] flex items-center gap-x-2 rounded-[0.375rem] h-full"
                        onClick={() => setIsDialogOpen(true)}
                    >
                        <div className="h-[19px] w-[19px] rounded-full bg-white flex justify-center items-center text-[1rem]">
                            <span className="pb-[0.10rem] text-[#299f2d]">+</span>
                        </div>
                        <div className="text-[1.125rem] text-white">IN</div>
                    </button>
                </DialogTrigger>
                <DialogContent
                    className=" top-56 w-[95vw]"
                    style={{ borderRadius: "10px" }}
                >
                    <DialogTitle>Add Entry (+)</DialogTitle>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="">Amount*</label>
                        <input
                            type="text"
                            className="w-full sc px-3 outline-none h-[50px]"
                            placeholder="1,00,000"
                            style={{ borderRadius: "5px" }}
                            name="customerName"
                            value={value}
                            onChange={handleChange}
                        />
                        <button
                            type="submit"
                            className="flex active:bg-opacity-70 gap-x-2 text-[18px] h-[50px] py-4 mt-4 text-white justify-center items-center w-full font-[500] bg-[#299F2D]"
                            style={{ borderRadius: "8px" }}
                        >
                            Add
                        </button>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};