// First, let's create the types (in types/types.js)
export const SET_RENT_YEAR = "SET_RENT_YEAR";
export const GET_RENT_YEAR = "GET_RENT_YEAR";

// Create the reducer (in reducers/rentYearReducer.js)
const defaultState = {
    selectedYear: new Date().getFullYear()
}

export const rentYearReducer = (state = defaultState, action) => {
    switch(action.type) {
        case SET_RENT_YEAR:
            return {
                ...state,
                selectedYear: action.data
            }
        case GET_RENT_YEAR:
            return {
                ...state
            }
        default:
            return {
                ...state
            }
    }
}

// Create actions (in actions/rentYearAction.js)
export const setRentYear = (year) => {
    return (dispatch) => {
        dispatch({
            type: SET_RENT_YEAR,
            data: year
        })
    }
}

export const getRentYear = () => {
    return (dispatch) => {
        dispatch({
            type: GET_RENT_YEAR
        })
    }
}