import React, { useEffect, useState } from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { editInquiry, getInquiryById } from '../redux/actions/inquiryAction';
import { contactSvg, whatsappSvg } from '../svgs/svgs';
import DeleteInquiry from '../components/customUi/delete-inquiry-dialog';
import { EditInquiryDialog } from '../components/customUi/edit-inquiry-dialog';
import axios from 'axios';
import { baseUrl } from '../lib/base-url';
import { SendHorizonal } from 'lucide-react';
import { MessageComp } from './flat-details-page';

function InquiryDetails() {
    const navigate = useNavigate();
    const { series, id } = useParams();
    const inquiry = useSelector((state) => state.inquiryReducer.inquiryObj);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.userReducer.user);
    const [note, setNote] = useState("");

    useEffect(() => {
        dispatch(getInquiryById(id));
    }, [id, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.get(`${baseUrl}/api/inquiry/get/${id}`)
            .then((res) => {
                const existingArr = res.data.notes || []; // Retrieve existing notes array or initialize as empty array if not present

                const noteObj = {
                    note: note,
                    createdBy: user?.userName,
                    imageUrl: user?.imageUrl,
                    createdAt: Date.now(),
                };

                // Create a new array by appending the new noteObj to the existing notes array
                const updatedNotes = [...existingArr, noteObj];

                // Dispatch action to update flat by replacing notes array with the updated one
                dispatch(editInquiry(id, { notes: updatedNotes }, user?.teamId, user?.userName, user?.imageUrl, `${note}`, `commented ${series} ${inquiry?.customerName} inquiry`, `/inquiry-details/${series}/${id}`));

                // Clear the note input field
                setNote("");
            })
            .catch((err) => {
                console.error("Error fetching flat details:", err);
                setNote("");
            });
    };

    function convertTimeToCustomFormat(timeString) {
        // Convert provided time string to a Date object
        const date = new Date(timeString);

        // Define months array
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        // Extract date components
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        // Format the date in the desired format
        const formattedDate = `${months[month]}, ${day}, ${year}`;

        return formattedDate;
    }

    const handleDeleteMessage = (timestamp) => {
        // Filter out the message with the given timestamp
        const updatedNotes = inquiry.notes.filter(note => note.createdAt !== timestamp);
        // Update the notes array
        dispatch(editInquiry(id, { notes: updatedNotes }, user?.teamId, user?.userName, user?.imageUrl, `Deleted a message from ${series} ${inquiry?.customerName} inquiry`, 'Delete message from inquiry' ,`/inquiry-details/${series}/${id}`));
    };

    const handlePhoneIconClick = (contact) => {
        if (contact) {
            window.location.href = `tel:${contact}`;
        }
    };

    const handleWhatsappIconClick = (contact) => {
        if (contact) {
            // Assuming contact is a valid WhatsApp number
            const whatsappLink = `https://wa.me/${contact.replace(/\s/g, "")}`;
            window.location.href = whatsappLink;
        }
    };

    return (
        <div className='h-[100vh] relative'>
            <div className=" h-[8%] flex justify-between items-center border-b ps-1 pe-3 border-[#e7eoec]">
                <div className=" flex justify-between items-center gap-x-1">
                    <div onClick={() => navigate("/inquiry-record")} className="flex p-2 rounded-full justify-center items-center">
                        <BiArrowBack size={24} />
                    </div>
                    <div className=" text-xl font-medium cursor-pointer">Inquiry #{series}</div>
                </div>
                <div className='flex items-center gap-x-8'>
                    <DeleteInquiry id={id} />
                    <EditInquiryDialog inquiryObj={inquiry} />
                </div>
            </div>
            <div className='h-[84%] overflow-y-auto px-3'>
                <div className='border-b py-3 border-[#DEE2F4]'>
                    <div className='text-[18px]'>Name</div>
                    <div className='text-xl font-semibold'>{inquiry?.customerName}</div>
                </div>
                <div className='border-b flex items-center justify-between py-3 border-[#DEE2F4]'>
                    <div>
                        <div className='text-[18px]'>Mobile No.</div>
                        <div className='text-xl font-semibold'>{inquiry?.contact}</div>
                    </div>
                    <div className='flex items-center gap-x-3'>
                        <div onClick={() => handlePhoneIconClick(inquiry?.contact)} className='bg-black rounded-full p-[8px]'>{contactSvg}</div>
                        <div onClick={() => handleWhatsappIconClick(inquiry?.contact)}>{whatsappSvg}</div>
                    </div>
                </div>
                {!(inquiry?.brokerNumber === undefined || inquiry?.brokerNumber === "") && (
                    <div className='border-b flex items-center justify-between py-3 border-[#DEE2F4]'>
                        <div>
                            <div className='text-[18px]'>Dalal/Broker Number.</div>
                            <div className='text-xl font-semibold'>{inquiry?.brokerNumber}</div>
                        </div>
                        <div className='flex items-center gap-x-3'>
                            <div onClick={() => handlePhoneIconClick(inquiry?.brokerNumber)} className='bg-black rounded-full p-[8px]'>{contactSvg}</div>
                            <div onClick={() => handleWhatsappIconClick(inquiry?.brokerNumber)}>{whatsappSvg}</div>
                        </div>
                    </div>
                )}
                <div className='border-b py-3 border-[#DEE2F4]'>
                    <div className='text-[18px]'>Inquired project</div>
                    <div className='text-xl font-semibold'>{inquiry?.project}</div>
                </div>
                {!(inquiry?.createdBy === undefined || inquiry?.createdBy === "") && (
                    <div className='border-b flex items-center py-3 border-[#DEE2F4]'>
                        <div>
                            <div className='text-[18px]'>Added By</div>
                            <div className='text-xl font-semibold'>{inquiry?.createdBy}</div>
                        </div>
                    </div>
                )}
                <div className='border-b flex items-center py-3 border-[#DEE2F4]'>
                    <div>
                        <div className='text-[18px]'>Date</div>
                        <div className='text-xl font-semibold'>{convertTimeToCustomFormat(inquiry?.createdAt)}</div>
                    </div>
                </div>

                <div>
                    {inquiry?.notes?.map((note, index) => (
                        <MessageComp
                            key={index}
                            name={note?.createdBy}
                            imageUrl={note?.imageUrl}
                            message={note?.note}
                            time={note?.createdAt}
                            onDelete={handleDeleteMessage}
                        />
                    ))}
                </div>
            </div>
            <div className="h-[8vh]">
                <div className={`w-full mb-3 px-3 bg-[#FAF8FF]`}>
                    <form onSubmit={handleSubmit} className='flex items-center gap-x-2'>
                        <input
                            type="text"
                            className='h-[50px] focus:outline-none px-3 rounded-full w-full'
                            placeholder='Send Message'
                            name='note'
                            onChange={(e) => {
                                setNote(e.target.value);
                            }}
                            value={note}
                        />
                        <button disabled={!note.trim()} className='bg-[#0066FA] p-2 rounded-full' type='submit'><SendHorizonal color="white" /></button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default InquiryDetails
